<template>
  <b-card>
    <b-card>
    </b-card>
    <b-row>
      <b-col v-for="image in images" :key="image._id" cols="2" class="h-100">
        <div class="thumbnail-wrap">
          <div class="actions">
            <b-button
              variant="gradient-primary"
              class="btn-icon rounded-circle"
              @click="deleteFile(image)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
            <b-button
              @click="doCopy(image.url)"
              variant="gradient-warning"
              class="btn-icon rounded-circle ml-1"
            >
              <feather-icon icon="ClipboardIcon" />
            </b-button>
          </div>
          <div class="thumbnail-col">
            <b-img
              v-if="image.type != 'pdf' && image.type != 'mp4'"
              thumbnail
              fluid
              :src="image.url"
              class="thumbnail-image"
            />
            <video v-if="image.type == 'mp4'" class="thumbnail-image" style="width: 100%">
              <source :src="image.url" type="video/mp4"/>
            </video>
            
            <div class="document-block" v-if="image.type == 'pdf'">
              <div class="document-padge">
                <span class="d-flex align-items-center">
                  <feather-icon icon="FileIcon" size="48" />
                </span>
              </div>

              <div class="file-name">
                <span>{{ image.key }}</span>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, BImg, BAvatar } from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BImg,
    ToastificationContent,
    BAvatar,
  },
  data() {
    return {
      images: [],
    };
  },
  directives: {
    Ripple,
  },
  methods: {
    getMediaType(media) {
      return (media.match(/[^\\\/]\.([^.\\\/]+)$/) || [null]).pop();
    },
    getGallery() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/gallery/get`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          const gallery = [];
          for (let i = 0; i < response.data.length; i++) {
            const media = response.data[i];
            console.log('media', response.data[0]);
            const type = this.getMediaType(media.url);
            gallery.push({ ...media, type });
          }
          this.images = gallery;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    deleteFile(file) {
      if (
        window.confirm(
          "You are about to permanently delete this item from your site. This action cannot be undone. 'Cancel' to stop, 'OK' to delete"
        )
      ) {
        const { _id: fileid } = file;
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/gallery/delete`,
            { image: [fileid] },
            { headers: { Authorization: `Bearer ${getUserToken()}` } }
          )
          .then(() => {
            const index = this.findFileIndex(fileid);
            this.images.splice(index, 1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    findFileIndex(fileID) {
      const index = this.images.findIndex((image) => {
        const { _id: imageIdentifier } = image;
        if (imageIdentifier === fileID) {
          return true;
        }
        return false;
      });
      return index;
    },
    doCopy(url) {
      this.$copyText(url).then(
        () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Text copied",
              icon: "BellIcon",
            },
          });
        },
        (e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Can not copy!",
              icon: "BellIcon",
            },
          });
        }
      );
    },
  },
  mounted() {
    this.getGallery();
  },
};
</script>

<style scoped>
.thumbnail-col {
  width: 155px;
  height: 155px;
  overflow: hidden;
  position: relative;
  margin: 10px 0;
  background-color: #f1f1f1;
  cursor: pointer;
}
.thumbnail-image {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.thumbnail-wrap .actions {
  display: none;
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 1;
}
.thumbnail-wrap:hover .actions {
  display: block;
}
.thumbnail-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}
.document-block {
  position: relative;
  height: 100%;
}
.file-name {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
}
.document-padge {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  padding-bottom: 30px;
}
</style>